import type { ModuleCarouselFieldsFragment } from "@/lib/contentful/__generated/graphql.types";
import {
    Box,
    Carousel,
    CarouselPagination,
    CarouselA11y,
    CldImage,
    Heading,
    Text,
    hasCloudinaryAsset,
    Flex,
    CarouselNavigationButton,
    getAspectRatio,
    AspectRatio,
    GridItem,
    CarouselSlide,
} from "@project/ui";
import type { CarouselClass } from "@project/ui";
import { useState } from "react";
import { ArticleCard } from "@/components/contentful/page-article/article-card";
import { DriverCard } from "../page-driver/driver-card";
import { ModuleSpacer } from "@/components/module-spacer";
import type {
    AllPageArticleCollectionQuery,
    PageArticleLinkToFieldsFragment,
} from "../page-article/__generated/page-article.contentful.generated";
import { ModuleHeader } from "@/components/module-header";
import { BasicPageCard } from "@/components/contentful/page-basic/basic-page-card";

export type ModuleCarouselProps = ModuleCarouselFieldsFragment & {
    pages?: AllPageArticleCollectionQuery["pages"] | null;
    isEmbedded?: boolean;
};

export const ModuleCarousel = ({
    title,
    description,
    carouselItemsCollection,
    imageDescriptionPlacement,
    pages,
    isEmbedded,
}: ModuleCarouselProps) => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [swiperInstance, setSwiperInstance] = useState<CarouselClass | null>(null);

    const aspectRatios = ["16:9", "1:1", "4:3"];

    const showImageDescriptionBelow =
        !imageDescriptionPlacement || imageDescriptionPlacement === "below";

    const articleCardStyles = {
        minW: {
            md: "320px",
        },
        maxW: {
            base: "full",
            md: "320px",
        },
    };

    return (
        <ModuleSpacer
            overflow="hidden"
            {...(isEmbedded && { wrapperProps: { px: { base: 5, md: 10, l: 0 } } })}
        >
            <Box color="porscheBlack" transition="all 0.25s ease">
                <ModuleHeader title={title} description={description}>
                    <GridItem
                        colSpan={1}
                        gridColumnStart={12}
                        display={{
                            base: "none",
                            md: "flex",
                        }}
                        justifyContent="flex-end"
                        alignItems="self-end"
                    >
                        <Flex justifyContent="flex-end" gap={7} padding={2}>
                            <CarouselNavigationButton
                                onNavigationClick={() => swiperInstance?.slidePrev()}
                                direction="prev"
                                hideLabel
                                disabled={swiperInstance?.isBeginning}
                                aria={{
                                    "aria-label": "previous",
                                }}
                            />
                            <CarouselNavigationButton
                                onNavigationClick={() => swiperInstance?.slideNext()}
                                direction="next"
                                hideLabel
                                disabled={swiperInstance?.isEnd}
                                aria={{
                                    "aria-label": "next",
                                }}
                            />
                        </Flex>
                    </GridItem>
                </ModuleHeader>

                {(carouselItemsCollection?.items.length || pages) && (
                    <Box>
                        <Carousel
                            slidesPerView="auto"
                            modules={[CarouselA11y]}
                            spaceBetween="12px"
                            overflow="visible"
                            breakpoints={{
                                1000: {
                                    spaceBetween: "32px",
                                },
                            }}
                            updateOnWindowResize={false}
                            onSwiper={(swiper) => {
                                setSwiperInstance(swiper);
                            }}
                            onSlideChange={({ activeIndex }) => {
                                setActiveSlideIndex(activeIndex);
                            }}
                        >
                            {pages?.items?.map((item, index) => (
                                <CarouselSlide
                                    key={`${item?.sys?.id}-${index}`}
                                    {...articleCardStyles}
                                >
                                    <ArticleCard
                                        item={item as PageArticleLinkToFieldsFragment}
                                        ratio={aspectRatios[index % 3]}
                                    />
                                </CarouselSlide>
                            ))}

                            {!pages &&
                                carouselItemsCollection?.items.map((item, index) => {
                                    switch (item?.__typename) {
                                        case "PageDriver":
                                            return (
                                                <CarouselSlide
                                                    key={`${item?.sys?.id}-${index}`}
                                                    maxW={{
                                                        base: 230,
                                                        md: "calc(100% / 3 - 24px)",
                                                        l: "calc(100% / 4 - 24px)",
                                                    }}
                                                >
                                                    <DriverCard item={item} isEmbedded={true} />
                                                </CarouselSlide>
                                            );
                                        case "PageArticle":
                                            return (
                                                <CarouselSlide
                                                    key={`${item?.sys?.id}-${index}`}
                                                    {...articleCardStyles}
                                                >
                                                    <ArticleCard
                                                        item={item}
                                                        ratio={aspectRatios[index % 3]}
                                                    />
                                                </CarouselSlide>
                                            );
                                        case "PageBasic":
                                            return (
                                                <CarouselSlide
                                                    key={`${item?.sys?.id}-${index}`}
                                                    maxW={{
                                                        base: 230,
                                                        md: "calc(100% / 3 - 24px)",
                                                        l: "calc(100% / 4 - 24px)",
                                                    }}
                                                >
                                                    <BasicPageCard item={item} />
                                                </CarouselSlide>
                                            );
                                        case "ModuleImage":
                                            return (
                                                <CarouselSlide
                                                    key={`${item?.sys?.id}-${index}`}
                                                    maxW="85%"
                                                >
                                                    <AspectRatio
                                                        ratio={{
                                                            base: "9:16",
                                                            l: getAspectRatio(
                                                                item.asset[0].width,
                                                                item.asset[0].height
                                                            ),
                                                        }}
                                                    >
                                                        {hasCloudinaryAsset(item?.asset) && (
                                                            <Box rounded="large" overflow="hidden">
                                                                <CldImage
                                                                    cloudinaryAsset={item?.asset}
                                                                    sizes="85vw"
                                                                    crop="fill"
                                                                    alt={item.alt ?? ""}
                                                                />
                                                            </Box>
                                                        )}
                                                    </AspectRatio>
                                                    {showImageDescriptionBelow && (
                                                        <Text
                                                            size="small"
                                                            color="porscheBlack"
                                                            mt={4}
                                                        >
                                                            {item?.description}
                                                        </Text>
                                                    )}
                                                    {!showImageDescriptionBelow && (
                                                        <Flex
                                                            position="absolute"
                                                            top="0"
                                                            left="0"
                                                            width="100%"
                                                            height="100%"
                                                            minHeight="100%"
                                                            bgColor="porscheBlack"
                                                            bg="linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)"
                                                            flexDir="column"
                                                            justifyContent="flex-end"
                                                            px={{
                                                                base: 4,
                                                                l: 8,
                                                            }}
                                                            gap={{
                                                                base: 4,
                                                                l: 6,
                                                            }}
                                                            pb={{
                                                                base: 6,
                                                                l: 10,
                                                            }}
                                                            color="allWhite"
                                                            rounded="large"
                                                        >
                                                            {item.title && (
                                                                <Heading size="headingMedium">
                                                                    {item.title}
                                                                </Heading>
                                                            )}
                                                            <Text size="small">
                                                                {item?.description}
                                                            </Text>
                                                        </Flex>
                                                    )}
                                                </CarouselSlide>
                                            );
                                        default:
                                            return null;
                                    }
                                })}
                            <CarouselPagination
                                activeSlideIndex={activeSlideIndex}
                                justifyContent="center"
                                mt={9}
                            />
                        </Carousel>
                    </Box>
                )}
            </Box>
        </ModuleSpacer>
    );
};
