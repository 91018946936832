import type { BoxProps } from "@project/ui";
import { Box, Flex, Tag, TagLabel } from "@project/ui";
import { useState } from "react";
import type { ContentTagFieldsFragment } from "@/lib/contentful/__generated/graphql.types";
import { motion } from "framer-motion";
import { CloseIcon } from "./svgs/close-icon";

type TagsProps = BoxProps & {
    tagsCollection: Array<ContentTagFieldsFragment | null> | null | undefined;
};

export const Tags = ({ tagsCollection, ...rest }: TagsProps) => {
    const tags = tagsCollection ?? [];
    const [showAllTags, setShowAllTags] = useState(false);
    const [showToggleBtn, setShowToggleBtn] = useState(true);

    const toggleShowAllTags = () => {
        setShowAllTags(!showAllTags);
    };

    return (
        <Box position="relative" height={14} overflow="visible" {...rest}>
            <Flex wrap="wrap" mb={{ base: 4, md: 8 }} position="absolute" zIndex={2}>
                {(showAllTags ? tags : tags.slice(0, 2)).map((tag) => (
                    <motion.div
                        key={tag?.sys?.id}
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        onAnimationStart={() => setShowToggleBtn(false)}
                        onAnimationComplete={() => setShowToggleBtn(true)}
                    >
                        <Tag mr={2} mb={2}>
                            <TagLabel>{tag?.label}</TagLabel>
                        </Tag>
                    </motion.div>
                ))}
                {tags.length > 2 && showToggleBtn && (
                    <Box
                        as="button"
                        aria-label={showAllTags ? "Show less" : "Show all"}
                        onClick={toggleShowAllTags}
                        py={3}
                        px={showAllTags ? 1 : 2}
                        h={5}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        backgroundColor="grey100"
                        borderRadius="0.25rem"
                        transition="all 0.25s ease"
                        fontSize="small"
                        color={"porscheBlack"}
                        _hover={{ cursor: "pointer", backgroundColor: "grey200" }}
                    >
                        {showAllTags ? <CloseIcon /> : "..."}
                    </Box>
                )}
            </Flex>
        </Box>
    );
};
