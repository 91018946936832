export const CloseIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path
                d="M13.5 5.02941L9.52941 9L13.5 12.9706L12.9706 13.5L9 9.52941L5.02941 13.5L4.5 12.9706L8.47059 9L4.5 5.02941L5.02941 4.5L9 8.47059L12.9706 4.5L13.5 5.02941Z"
                fill="#010205"
            />
        </svg>
    );
};
